<template>
	<div class="gallery">
		<!-- <div v-if="isLoading" class="gallery__loader">Loading...</div> -->
		<div class="gallery__content edit-avatar">
			<h2 class="edit-avatar__title">{{ $t('titles.edit-photo') }}</h2>

			<div class="edit-avatar__wrapper">
				<div class="edit-avatar__photo avatar-changing">
					<div class="avatar-changing__wrapper">
						<div class="avatar-changing__img-container">
							<tk-image
								v-if="selectedImg"
								width="250"
								height="250"
								:src="selectedImg"
								class="avatar-changing__img"
							/>
						</div>

						<label
              @click="notifyAboutAvatarsLimit()"
							for="upload-avatar"
							:class="['avatar-changing__select', { disabled: isLoading }]"
						>
							<input
								@change="saveFile"
								:disabled="isLoading || avatarsCountReachLimit"
								form="profile-form"
								class="avatar-changing__select--input"
								type="file"
								id="upload-avatar"
								accept=".jpg, .png, .jpeg"
							/>
						</label>
					</div>

					<div class="avatar-changing__buttons">
						<tk-button
							@click="chooseAvatar"
							:disabled="isLoading || isDisabledSetAvatarButton"
							class="avatar-changing__btn"
						>
							{{ $t('form.buttons.choose_avatar') }}
						</tk-button>
						<tk-button
							@click="del"
							:disabled="isLoading || !isSelectImg || this.userAvatars.length <= 1"
							class="avatar-changing__btn"
							kind="main-brown"
						>
							{{ $t('form.buttons.delete_photo') }}
						</tk-button>
					</div>
				</div>

				<div class="edit-avatar__gallery avatar-gallery">
					<ul class="avatar-gallery__list">
						<li
							v-for="img in userAvatars"
							:key="img.hash"
							class="avatar-gallery__item"
              :class="{ 'selected': img.hash === this.selectedImg?.hash }"
						>
							<tk-image
                v-if="img"
								class="avatar-gallery__item--content"
								@click.self="selectImage(img)"
								:src="img"
								width="98"
								height="98"
							/>
						</li>
            <li v-if="isLoading">
              <tk-image
                class="avatar-gallery__item--content"
                :src="_loadingImageUrl"
                width="98"
                height="98"
              />
            </li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	DELETE_AVATAR,
	SAVE_AVATAR,
	SET_AVATAR
} from '@/constants/store/avatar'

import sizes from '@/constants/sizes'

// Refactoring
const AVATARS_COUNT_LIMIT = 12;

export default {
	data() {
		return {
			selectedImg: null,
			isLoading: false,
		}
	},
	computed: {
    avatarsCountReachLimit() {
      return this.userAvatars.length >= AVATARS_COUNT_LIMIT;
    },
		maxPhotoSize() {
			return sizes.megabyte * 5
		},
		isDisabledSetAvatarButton() {
      return !this.selectedImg || this.selectedImg.is_active
		},
		isSelectImg() {
			return !!this.selectedImg
		},
		userAvatars() {
			return this.$store.state.user.avatars
		},
    currentAvatar() {
      return this.$store.state.avatar.content
    },
		_loadingImageUrl() {
			return require('@/assets/img/svg/common-loader.svg')
		}
	},
	methods: {
		async saveFile({ target }) {
			if (target.files[0] && target.files[0].size > this.maxPhotoSize) {
				this.$store.commit('showNotification', { type: 'error', text: 'Максимальный размер фото - 5Мб' })

				target.value = ''

				return
			}

			this.isLoading = true
			const response = await this.$store.dispatch(SAVE_AVATAR, target.files[0])
			this.isLoading = false

			if (!response || (!response.hash && !response.status)) {
				this.$store.commit('showNotification', { type: 'error', text: 'Ошибка сохранения фотографии' })

				target.value = ''
				return
			}

      this.selectImage(response)

			target.value = ''
		},
		async chooseAvatar() {
			const response = await this.$store.dispatch(SET_AVATAR, this.selectedImg)

			if (response && !response.status) {
				return
			}
		},
		async del() {
			const response = await this.$store.dispatch(DELETE_AVATAR, this.selectedImg)

			if (response && !response.status) {
				this.$store.commit('showNotification', { type: 'Error', text: 'Ошибка удаления фотографии' })

				return
			}

      this.selectImage()
		},
    notifyAboutAvatarsLimit() {
      if (this.avatarsCountReachLimit) {
        this.$store.commit('showNotification', {
          type: 'error',
          text: 'Достингнуто максимальное количество аватарок. ' +
            'Пожалуйста, удалите одну из предыдущих, чтобы загрузить новую.'
        })
      }
    },
		selectImage(image) {
      if (image) {
        this.selectedImg = image
        return
      }

      if (!this.userAvatars.length) {
        this.selectedImg = null
        return
      }

      for (const avatar of this.userAvatars) {
        if (avatar.is_active) {
          this.selectedImg = avatar
          break
        }
      }
		}
	},
  created() {
    this.selectImage()
  }
}
</script>

<style lang="sass">
.edit-avatar
	position: relative
	max-width: 900px

	&__title
		width: 100%
		margin-bottom: 30px

		font-weight: bold
		font-size: 24px
		line-height: 24px

	&__wrapper
		display: flex
		flex-wrap: wrap
		justify-content: space-between
		align-items: center
		margin-bottom: 30px

	&__photo
		width: 100%
		max-width: 390px

	&__gallery
		width: 100%
		max-width: 460px

	.avatar-changing
		&__wrapper
			display: flex
			justify-content: space-between
			margin-bottom: 35px

		&__img-container
			width: 250px
			height: 250px

			background-color: #C4C4C4
			border: 1px solid #CBCBCB
			box-sizing: border-box
			border-radius: 11px

			overflow: hidden

		&__img
			width: 250px
			height: 250px
		img
			object-fit: cover

		&__select
			position: relative

			width: 100px
			height: 100px

			font-size: 0

			background-color: transparent
			border: 1px solid #CBCBCB
			box-sizing: border-box
			border-radius: 11px
			cursor: pointer

			&.disabled
				cursor: not-allowed

			&--input
				visibility: hidden

			&:after
				content: ''

				position: absolute
				top: 0
				right: 0
				bottom: 0
				left: 0

				width: 20px
				height: 20px
				margin: auto

				background: url("~@/assets/img/svg/icons/avatar-plus.svg") center no-repeat
				background-size: 20px 20px

		&__buttons
			display: flex
			justify-content: space-between

		&__btn
			width: 180px
			height: 55px

	.avatar-gallery
		@media (max-width: 1440px)
			margin-top: 20px

		&__list
			display: grid
			grid-gap: 20px
			grid-template-columns: 1fr 1fr 1fr 1fr
			grid-template-rows: 1fr 1fr 1fr
			margin: 0
			padding: 0

			list-style: none

		&__item
			width: 100px
			height: 100px
			border: 3px solid #CBCBCB
			background-color: #CBCBCB
			box-sizing: border-box
			border-radius: 11px
			overflow: hidden

			&--content
				cursor: pointer

			img
				object-fit: cover

.selected
  border: 3px solid #1AC386 !important
  transition: all .2s ease-in-out

// 540
@media screen and (max-width: 800px)
	.edit-avatar
		width: 100%

		&__gallery
			max-width: 320px

		.avatar-changing
			max-width: 340px

			&__wrapper
				justify-content: center
				flex-wrap: wrap

			&__img-container
				margin-bottom: 30px

			&__btn
				max-width: 160px

		.avatar-gallery
			margin-top: 30px

			&__list
				grid-template-columns: 1fr 1fr 1fr
				grid-template-rows: 1fr 1fr 1fr 1fr
</style>
